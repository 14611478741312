import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { ApiBaseService, PageResponse } from '@mp/shared/data-access';

import { Land } from './land';
import { CreateOrganisationAdresse, OrganisationAdresse, UpdateOrganisationAdresse } from './organisation-adresse';

@Injectable({ providedIn: 'root' })
export class OrganisationAdressenService extends ApiBaseService<
  OrganisationAdresse,
  CreateOrganisationAdresse,
  UpdateOrganisationAdresse
> {
  constructor(http: HttpClient) {
    super(http, '/api/my/organisation/adressen');
  }

  getLaender(): Observable<Array<Land>> {
    return this.http.get<PageResponse<Land>>('/api/core/stammdaten/laender').pipe(pluck('data'));
  }
}
