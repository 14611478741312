import { Action, createReducer, on } from '@ngrx/store';

import { BasicState, buildInitialBasicState } from '@mp/shared/data-access';

import { OrganisationAdresse } from '../organisation-adresse';

import { OrganisationAdressenActions } from './organisation-adressen.actions';

export const ORGANISATION_ADRESSEN_FEATURE_KEY = 'organisation-adressen';

export interface OrganisationAdressenPartialState {
  readonly [ORGANISATION_ADRESSEN_FEATURE_KEY]: OrganisationAdressenState;
}

export type OrganisationAdressenState = BasicState<OrganisationAdresse>;
export const initialState: OrganisationAdressenState = buildInitialBasicState();

const adressenReducer = createReducer(
  initialState,

  on(
    OrganisationAdressenActions.COMPONENT.loadSingle,
    OrganisationAdressenActions.COMPONENT.create,
    OrganisationAdressenActions.COMPONENT.update,

    (state): OrganisationAdressenState => ({
      ...state,
      isLoading: true,
    }),
  ),

  on(
    OrganisationAdressenActions.API.loadedSingleSuccessfully,

    (state, action): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
      selected: action.loadedAdresse,
    }),
  ),

  on(
    OrganisationAdressenActions.API.loadedAllSuccessfully,

    (state, action): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
      entities: action.loadedAdressenPage.data,
      pagination: action.loadedAdressenPage.pagination,
    }),
  ),

  on(
    OrganisationAdressenActions.API.createdSuccessfully,

    (state, action): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
      entities: [action.createdAdresse, ...(state.entities ?? [])],
    }),
  ),

  on(
    OrganisationAdressenActions.API.updatedSuccessfully,

    (state, action): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
      entities: [
        ...(state.entities ?? []).map((entity) =>
          entity.id === action.updatedAdresse.id ? action.updatedAdresse : entity,
        ),
      ],
      selected: undefined,
    }),
  ),

  on(
    OrganisationAdressenActions.API.loadedAllUnsuccessfully,
    OrganisationAdressenActions.API.loadedSingleUnsuccessfully,
    OrganisationAdressenActions.API.createdUnsuccessfully,
    OrganisationAdressenActions.API.updatedUnsuccessfully,

    (state): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
    }),
  ),

  on(
    OrganisationAdressenActions.API.canceledCreate,
    OrganisationAdressenActions.API.canceledUpdate,

    (state): OrganisationAdressenState => ({
      ...state,
      isLoading: false,
      selected: undefined,
    }),
  ),
);

export function organisationAdressenReducer(
  state: OrganisationAdressenState | undefined,
  action: Action,
): OrganisationAdressenState {
  return adressenReducer(state, action);
}
