import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ORGANISATION_ADRESSEN_FEATURE_KEY, OrganisationAdressenState } from './organisation-adressen.reducer';

const getOrganisationAdressenState = createFeatureSelector<OrganisationAdressenState>(
  ORGANISATION_ADRESSEN_FEATURE_KEY,
);

const IS_LOADING = createSelector(getOrganisationAdressenState, (state) => state.isLoading);

const LIST = createSelector(getOrganisationAdressenState, (state) => state.entities);

const SELECTED = createSelector(getOrganisationAdressenState, (state) => state.selected);

export const organisationAdressenSelectors = {
  IS_LOADING,
  SELECTED,
  LIST,
};
