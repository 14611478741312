import { createAction, props } from '@ngrx/store';

import { QueryParams } from '@core/shared/util';
import { PageResponse } from '@mp/shared/data-access';

import { CreateOrganisationAdresse, OrganisationAdresse, UpdateOrganisationAdresse } from '../organisation-adresse';

const loadSingle = createAction(
  '[Organisations-Adressen Edit Component] Load Single Organisations-Adresse',
  props<{ queryParams?: QueryParams<OrganisationAdresse, Partial<OrganisationAdresse>> }>(),
);

const loadedSingleSuccessfully = createAction(
  '[Organisations-Adressen Service] Loaded Single Organisations-Adresse',
  props<{ loadedAdresse: OrganisationAdresse }>(),
);

const loadedSingleUnsuccessfully = createAction(
  '[Organisations-Adressen Service] Loaded Single Organisations-Adresse Unsuccessfully',
  props<{ error: any }>(),
);

const loadAll = createAction(
  '[Organisations-Adressen List Component] Load All Organisations-Adressen',
  props<{ queryParams?: QueryParams<OrganisationAdresse, Partial<OrganisationAdresse>> }>(),
);

const loadedAllSuccessfully = createAction(
  '[Organisations-Adressen Service] Loaded All Organisations-Adressen',
  props<{ loadedAdressenPage: PageResponse<OrganisationAdresse> }>(),
);

const loadedAllUnsuccessfully = createAction(
  '[Organisations-Adressen Service] Loaded All Organisations-Adressen Unsuccessfully',
  props<{ error: any }>(),
);

const create = createAction(
  '[Organisations-Adressen Create Component] Create Organisations-Adresse',
  props<{ adresseToCreate: CreateOrganisationAdresse }>(),
);

const createdSuccessfully = createAction(
  '[Organisations-Adressen Service] Created Organisations-Adresse',
  props<{ createdAdresse: OrganisationAdresse }>(),
);

const createdUnsuccessfully = createAction(
  '[Organisations-Adressen Service] Could Not Create Organisations-Adresse',
  props<{ error: any }>(),
);

const update = createAction(
  '[Organisations-Adressen Edit Component] Update Organisations-Adresse',
  props<{ adresseToUpdate: UpdateOrganisationAdresse }>(),
);

const updatedSuccessfully = createAction(
  '[Organisations-Adressen Service] Updated Organisations-Adresse',
  props<{ updatedAdresse: OrganisationAdresse }>(),
);

const updatedUnsuccessfully = createAction(
  '[Organisations-Adressen Service] Could Not Update Organisations-Adresse',
  props<{ error: any }>(),
);

const cancelUpdate = createAction('[Organisations-Adressen Edit Component] Cancel Organisations-Adressen Update');

const canceledUpdate = createAction('[Organisations-Adressen Edit Component] Canceled Organisations-Adressen Update');

const cancelCreate = createAction('[Organisations-Adressen Create Component] Cancel Organisations-Adressen Create');

const canceledCreate = createAction('[Organisations-Adressen Create Component] Canceled Organisations-Adressen Create');

export namespace OrganisationAdressenActions {
  export const API = {
    loadedSingleSuccessfully,
    loadedSingleUnsuccessfully,
    loadedAllSuccessfully,
    loadedAllUnsuccessfully,
    createdSuccessfully,
    createdUnsuccessfully,
    updatedSuccessfully,
    updatedUnsuccessfully,
    canceledCreate,
    canceledUpdate,
  };

  export const COMPONENT = {
    loadSingle,
    loadAll,
    create,
    update,
    cancelCreate,
    cancelUpdate,
  };
}
